
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import Tag from "./modules/Tag.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    Message,
    Tag,
  },
  data() {
    return {
      introduceImg:require('@/assets/1new/关于我们/2.jpg'),
      developmentImg:require('@/assets/1new/关于我们/3.jpg'),
      royalImg:require('@/assets/1new/关于我们/4.jpg'),
      professor1:require('@/assets/1new/关于我们/5.jpg'),
      professor2:require('@/assets/1new/关于我们/6.jpg'),
      professor3:require('@/assets/1new/关于我们/7.jpg'),
    };
  },
});
